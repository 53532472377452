import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

import * as _tsparticles2 from "tsparticles";

var _tsparticles = "default" in _tsparticles2 ? _tsparticles2.default : _tsparticles2;

import * as _react4 from "fast-deep-equal/react";

var _react3 = "default" in _react4 ? _react4.default : _react4;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(() => {
  var t = {
    757: (t, e, r) => {
      t.exports = r(666);
    },
    312: (t, e, r) => {
      "use strict";

      function n(t, e) {
        for (var r = 0; r < e.length; r++) {
          var n = e[r];
          n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n);
        }
      }

      function o(t) {
        return (o = Object.setPrototypeOf ? Object.getPrototypeOf : function (t) {
          return t.__proto__ || Object.getPrototypeOf(t);
        })(t);
      }

      function i(t, e) {
        for (; !Object.prototype.hasOwnProperty.call(t, e) && null !== (t = o(t)););

        return t;
      }

      function a() {
        return (a = "undefined" != typeof Reflect && Reflect.get ? Reflect.get : function (t, e, r) {
          var n = i(t, e);

          if (n) {
            var o = Object.getOwnPropertyDescriptor(n, e);
            return o.get ? o.get.call(arguments.length < 3 ? t : r) : o.value;
          }
        }).apply(this || _global, arguments);
      }

      function c(t, e) {
        return (c = Object.setPrototypeOf || function (t, e) {
          return t.__proto__ = e, t;
        })(t, e);
      }

      function u(t) {
        return (u = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
          return typeof t;
        } : function (t) {
          return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
        })(t);
      }

      function s(t, e) {
        if (e && ("object" === u(e) || "function" == typeof e)) return e;
        if (void 0 !== e) throw new TypeError("Derived constructors may only return object or undefined");
        return function (t) {
          if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
          return t;
        }(t);
      }

      r.d(e, {
        Z: () => _
      });
      var l = r(757),
          f = r.n(l);
      const p = _react;
      var h = r.n(p);
      const d = _tsparticles,
            y = _react3;
      var v = r.n(y);

      var b = function (t, e, r, n) {
        return new (r || (r = Promise))(function (o, i) {
          function a(t) {
            try {
              u(n.next(t));
            } catch (t) {
              i(t);
            }
          }

          function c(t) {
            try {
              u(n.throw(t));
            } catch (t) {
              i(t);
            }
          }

          function u(t) {
            var e;
            t.done ? o(t.value) : (e = t.value, e instanceof r ? e : new r(function (t) {
              t(e);
            })).then(a, c);
          }

          u((n = n.apply(t, e || [])).next());
        });
      },
          _ = function (t) {
        !function (t, e) {
          if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
          Object.defineProperty(t, "prototype", {
            value: Object.create(e && e.prototype, {
              constructor: {
                value: t,
                writable: !0,
                configurable: !0
              }
            }),
            writable: !1
          }), e && c(t, e);
        }(p, t);
        var e,
            r,
            i,
            u,
            l = (i = p, u = function () {
          if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
          if (Reflect.construct.sham) return !1;
          if ("function" == typeof Proxy) return !0;

          try {
            return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})), !0;
          } catch (t) {
            return !1;
          }
        }(), function () {
          var t,
              e = o(i);

          if (u) {
            var r = o(this || _global).constructor;
            t = Reflect.construct(e, arguments, r);
          } else t = e.apply(this || _global, arguments);

          return s(this || _global, t);
        });

        function p(t) {
          var e;
          return function (t, e) {
            if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
          }(this || _global, p), (e = l.call(this || _global, t)).state = {
            library: void 0
          }, e;
        }

        return e = p, (r = [{
          key: "destroy",
          value: function () {
            (this || _global).state.library && ((this || _global).state.library.destroy(), this.setState({
              library: void 0
            }));
          }
        }, {
          key: "shouldComponentUpdate",
          value: function (t) {
            return !v()(t, (this || _global).props);
          }
        }, {
          key: "componentDidUpdate",
          value: function () {
            this.refresh();
          }
        }, {
          key: "forceUpdate",
          value: function () {
            var t = this || _global;
            this.refresh().then(function () {
              a(o(p.prototype), "forceUpdate", t).call(t);
            });
          }
        }, {
          key: "componentDidMount",
          value: function () {
            b(this || _global, void 0, void 0, f().mark(function t() {
              return f().wrap(function (t) {
                for (;;) switch (t.prev = t.next) {
                  case 0:
                    if (!(this || _global).props.init) {
                      t.next = 3;
                      break;
                    }

                    return t.next = 3, (this || _global).props.init(d.tsParticles);

                  case 3:
                    return t.next = 5, this.loadParticles();

                  case 5:
                  case "end":
                    return t.stop();
                }
              }, t, this || _global);
            }));
          }
        }, {
          key: "componentWillUnmount",
          value: function () {
            this.destroy();
          }
        }, {
          key: "render",
          value: function () {
            var t = (this || _global).props,
                e = t.width,
                r = t.height,
                n = t.className,
                o = t.canvasClassName,
                i = t.id;
            return h().createElement("div", {
              className: n,
              id: i
            }, h().createElement("canvas", {
              className: o,
              style: Object.assign(Object.assign({}, (this || _global).props.style), {
                width: e,
                height: r
              })
            }));
          }
        }, {
          key: "refresh",
          value: function () {
            return b(this || _global, void 0, void 0, f().mark(function t() {
              return f().wrap(function (t) {
                for (;;) switch (t.prev = t.next) {
                  case 0:
                    return this.destroy(), t.next = 3, this.loadParticles();

                  case 3:
                  case "end":
                    return t.stop();
                }
              }, t, this || _global);
            }));
          }
        }, {
          key: "loadParticles",
          value: function () {
            var t;
            return b(this || _global, void 0, void 0, f().mark(function e() {
              var r,
                  n,
                  o = this || _global;
              return f().wrap(function (e) {
                for (;;) switch (e.prev = e.next) {
                  case 0:
                    if (r = function (t) {
                      return b(o, void 0, void 0, f().mark(function e() {
                        return f().wrap(function (e) {
                          for (;;) switch (e.prev = e.next) {
                            case 0:
                              if ((this || _global).props.container && ((this || _global).props.container.current = t), this.setState({
                                library: t
                              }), !(this || _global).props.loaded) {
                                e.next = 5;
                                break;
                              }

                              return e.next = 5, (this || _global).props.loaded(t);

                            case 5:
                            case "end":
                              return e.stop();
                          }
                        }, e, this || _global);
                      }));
                    }, !(this || _global).props.url) {
                      e.next = 7;
                      break;
                    }

                    return e.next = 4, d.tsParticles.loadJSON((this || _global).props.id, (this || _global).props.url);

                  case 4:
                    e.t0 = e.sent, e.next = 10;
                    break;

                  case 7:
                    return e.next = 9, d.tsParticles.load((this || _global).props.id, null !== (t = (this || _global).props.params) && void 0 !== t ? t : (this || _global).props.options);

                  case 9:
                    e.t0 = e.sent;

                  case 10:
                    return n = e.t0, e.next = 13, r(n);

                  case 13:
                  case "end":
                    return e.stop();
                }
              }, e, this || _global);
            }));
          }
        }]) && n(e.prototype, r), Object.defineProperty(e, "prototype", {
          writable: !1
        }), p;
      }(p.Component);

      _.defaultProps = {
        width: "100%",
        height: "100%",
        options: {},
        style: {},
        url: void 0,
        id: "tsparticles"
      };
    },
    666: t => {
      var e = function (t) {
        "use strict";

        var e,
            r = Object.prototype,
            n = r.hasOwnProperty,
            o = "function" == typeof Symbol ? Symbol : {},
            i = o.iterator || "@@iterator",
            a = o.asyncIterator || "@@asyncIterator",
            c = o.toStringTag || "@@toStringTag";

        function u(t, e, r) {
          return Object.defineProperty(t, e, {
            value: r,
            enumerable: !0,
            configurable: !0,
            writable: !0
          }), t[e];
        }

        try {
          u({}, "");
        } catch (t) {
          u = function (t, e, r) {
            return t[e] = r;
          };
        }

        function s(t, e, r, n) {
          var o = e && e.prototype instanceof v ? e : v,
              i = Object.create(o.prototype),
              a = new L(n || []);
          return i._invoke = function (t, e, r) {
            var n = f;
            return function (o, i) {
              if (n === h) throw new Error("Generator is already running");

              if (n === d) {
                if ("throw" === o) throw i;
                return S();
              }

              for (r.method = o, r.arg = i;;) {
                var a = r.delegate;

                if (a) {
                  var c = x(a, r);

                  if (c) {
                    if (c === y) continue;
                    return c;
                  }
                }

                if ("next" === r.method) r.sent = r._sent = r.arg;else if ("throw" === r.method) {
                  if (n === f) throw n = d, r.arg;
                  r.dispatchException(r.arg);
                } else "return" === r.method && r.abrupt("return", r.arg);
                n = h;
                var u = l(t, e, r);

                if ("normal" === u.type) {
                  if (n = r.done ? d : p, u.arg === y) continue;
                  return {
                    value: u.arg,
                    done: r.done
                  };
                }

                "throw" === u.type && (n = d, r.method = "throw", r.arg = u.arg);
              }
            };
          }(t, r, a), i;
        }

        function l(t, e, r) {
          try {
            return {
              type: "normal",
              arg: t.call(e, r)
            };
          } catch (t) {
            return {
              type: "throw",
              arg: t
            };
          }
        }

        t.wrap = s;
        var f = "suspendedStart",
            p = "suspendedYield",
            h = "executing",
            d = "completed",
            y = {};

        function v() {}

        function b() {}

        function _() {}

        var g = {};

        g[i] = function () {
          return this || _global;
        };

        var m = Object.getPrototypeOf,
            O = m && m(m(k([])));
        O && O !== r && n.call(O, i) && (g = O);
        var w = _.prototype = v.prototype = Object.create(g);

        function j(t) {
          ["next", "throw", "return"].forEach(function (e) {
            u(t, e, function (t) {
              return this._invoke(e, t);
            });
          });
        }

        function P(t, e) {
          function r(o, i, a, c) {
            var u = l(t[o], t, i);

            if ("throw" !== u.type) {
              var s = u.arg,
                  f = s.value;
              return f && "object" == typeof f && n.call(f, "__await") ? e.resolve(f.__await).then(function (t) {
                r("next", t, a, c);
              }, function (t) {
                r("throw", t, a, c);
              }) : e.resolve(f).then(function (t) {
                s.value = t, a(s);
              }, function (t) {
                return r("throw", t, a, c);
              });
            }

            c(u.arg);
          }

          var o;

          (this || _global)._invoke = function (t, n) {
            function i() {
              return new e(function (e, o) {
                r(t, n, e, o);
              });
            }

            return o = o ? o.then(i, i) : i();
          };
        }

        function x(t, r) {
          var n = t.iterator[r.method];

          if (n === e) {
            if (r.delegate = null, "throw" === r.method) {
              if (t.iterator.return && (r.method = "return", r.arg = e, x(t, r), "throw" === r.method)) return y;
              r.method = "throw", r.arg = new TypeError("The iterator does not provide a 'throw' method");
            }

            return y;
          }

          var o = l(n, t.iterator, r.arg);
          if ("throw" === o.type) return r.method = "throw", r.arg = o.arg, r.delegate = null, y;
          var i = o.arg;
          return i ? i.done ? (r[t.resultName] = i.value, r.next = t.nextLoc, "return" !== r.method && (r.method = "next", r.arg = e), r.delegate = null, y) : i : (r.method = "throw", r.arg = new TypeError("iterator result is not an object"), r.delegate = null, y);
        }

        function M(t) {
          var e = {
            tryLoc: t[0]
          };
          1 in t && (e.catchLoc = t[1]), 2 in t && (e.finallyLoc = t[2], e.afterLoc = t[3]), (this || _global).tryEntries.push(e);
        }

        function E(t) {
          var e = t.completion || {};
          e.type = "normal", delete e.arg, t.completion = e;
        }

        function L(t) {
          (this || _global).tryEntries = [{
            tryLoc: "root"
          }], t.forEach(M, this || _global), this.reset(!0);
        }

        function k(t) {
          if (t) {
            var r = t[i];
            if (r) return r.call(t);
            if ("function" == typeof t.next) return t;

            if (!isNaN(t.length)) {
              var o = -1,
                  a = function r() {
                for (; ++o < t.length;) if (n.call(t, o)) return r.value = t[o], r.done = !1, r;

                return r.value = e, r.done = !0, r;
              };

              return a.next = a;
            }
          }

          return {
            next: S
          };
        }

        function S() {
          return {
            value: e,
            done: !0
          };
        }

        return b.prototype = w.constructor = _, _.constructor = b, b.displayName = u(_, c, "GeneratorFunction"), t.isGeneratorFunction = function (t) {
          var e = "function" == typeof t && t.constructor;
          return !!e && (e === b || "GeneratorFunction" === (e.displayName || e.name));
        }, t.mark = function (t) {
          return Object.setPrototypeOf ? Object.setPrototypeOf(t, _) : (t.__proto__ = _, u(t, c, "GeneratorFunction")), t.prototype = Object.create(w), t;
        }, t.awrap = function (t) {
          return {
            __await: t
          };
        }, j(P.prototype), P.prototype[a] = function () {
          return this || _global;
        }, t.AsyncIterator = P, t.async = function (e, r, n, o, i) {
          void 0 === i && (i = Promise);
          var a = new P(s(e, r, n, o), i);
          return t.isGeneratorFunction(r) ? a : a.next().then(function (t) {
            return t.done ? t.value : a.next();
          });
        }, j(w), u(w, c, "Generator"), w[i] = function () {
          return this || _global;
        }, w.toString = function () {
          return "[object Generator]";
        }, t.keys = function (t) {
          var e = [];

          for (var r in t) e.push(r);

          return e.reverse(), function r() {
            for (; e.length;) {
              var n = e.pop();
              if (n in t) return r.value = n, r.done = !1, r;
            }

            return r.done = !0, r;
          };
        }, t.values = k, L.prototype = {
          constructor: L,
          reset: function (t) {
            if ((this || _global).prev = 0, (this || _global).next = 0, (this || _global).sent = (this || _global)._sent = e, (this || _global).done = !1, (this || _global).delegate = null, (this || _global).method = "next", (this || _global).arg = e, (this || _global).tryEntries.forEach(E), !t) for (var r in this || _global) "t" === r.charAt(0) && n.call(this || _global, r) && !isNaN(+r.slice(1)) && ((this || _global)[r] = e);
          },
          stop: function () {
            (this || _global).done = !0;
            var t = (this || _global).tryEntries[0].completion;
            if ("throw" === t.type) throw t.arg;
            return (this || _global).rval;
          },
          dispatchException: function (t) {
            if ((this || _global).done) throw t;
            var r = this || _global;

            function o(n, o) {
              return c.type = "throw", c.arg = t, r.next = n, o && (r.method = "next", r.arg = e), !!o;
            }

            for (var i = (this || _global).tryEntries.length - 1; i >= 0; --i) {
              var a = (this || _global).tryEntries[i],
                  c = a.completion;
              if ("root" === a.tryLoc) return o("end");

              if (a.tryLoc <= (this || _global).prev) {
                var u = n.call(a, "catchLoc"),
                    s = n.call(a, "finallyLoc");

                if (u && s) {
                  if ((this || _global).prev < a.catchLoc) return o(a.catchLoc, !0);
                  if ((this || _global).prev < a.finallyLoc) return o(a.finallyLoc);
                } else if (u) {
                  if ((this || _global).prev < a.catchLoc) return o(a.catchLoc, !0);
                } else {
                  if (!s) throw new Error("try statement without catch or finally");
                  if ((this || _global).prev < a.finallyLoc) return o(a.finallyLoc);
                }
              }
            }
          },
          abrupt: function (t, e) {
            for (var r = (this || _global).tryEntries.length - 1; r >= 0; --r) {
              var o = (this || _global).tryEntries[r];

              if (o.tryLoc <= (this || _global).prev && n.call(o, "finallyLoc") && (this || _global).prev < o.finallyLoc) {
                var i = o;
                break;
              }
            }

            i && ("break" === t || "continue" === t) && i.tryLoc <= e && e <= i.finallyLoc && (i = null);
            var a = i ? i.completion : {};
            return a.type = t, a.arg = e, i ? ((this || _global).method = "next", (this || _global).next = i.finallyLoc, y) : this.complete(a);
          },
          complete: function (t, e) {
            if ("throw" === t.type) throw t.arg;
            return "break" === t.type || "continue" === t.type ? (this || _global).next = t.arg : "return" === t.type ? ((this || _global).rval = (this || _global).arg = t.arg, (this || _global).method = "return", (this || _global).next = "end") : "normal" === t.type && e && ((this || _global).next = e), y;
          },
          finish: function (t) {
            for (var e = (this || _global).tryEntries.length - 1; e >= 0; --e) {
              var r = (this || _global).tryEntries[e];
              if (r.finallyLoc === t) return this.complete(r.completion, r.afterLoc), E(r), y;
            }
          },
          catch: function (t) {
            for (var e = (this || _global).tryEntries.length - 1; e >= 0; --e) {
              var r = (this || _global).tryEntries[e];

              if (r.tryLoc === t) {
                var n = r.completion;

                if ("throw" === n.type) {
                  var o = n.arg;
                  E(r);
                }

                return o;
              }
            }

            throw new Error("illegal catch attempt");
          },
          delegateYield: function (t, r, n) {
            return (this || _global).delegate = {
              iterator: k(t),
              resultName: r,
              nextLoc: n
            }, "next" === (this || _global).method && ((this || _global).arg = e), y;
          }
        }, t;
      }(t.exports);

      try {
        _global.regeneratorRuntime = e;
      } catch (t) {
        Function("r", "regeneratorRuntime = r")(e);
      }
    },
    868: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    300: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    264: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    329: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    469: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    961: function (t, e, r) {
      "use strict";

      var n = (this || _global) && (this || _global).__createBinding || (Object.create ? function (t, e, r, n) {
        void 0 === n && (n = r), Object.defineProperty(t, n, {
          enumerable: !0,
          get: function () {
            return e[r];
          }
        });
      } : function (t, e, r, n) {
        void 0 === n && (n = r), t[n] = e[r];
      }),
          o = (this || _global) && (this || _global).__exportStar || function (t, e) {
        for (var r in t) "default" === r || Object.prototype.hasOwnProperty.call(e, r) || n(e, t, r);
      };

      Object.defineProperty(e, "__esModule", {
        value: !0
      }), o(r(300), e), o(r(329), e), o(r(264), e), o(r(469), e);
    },
    974: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    252: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    130: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    330: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    208: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    349: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    581: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    164: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    667: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    855: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    702: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    515: function (t, e, r) {
      "use strict";

      var n = (this || _global) && (this || _global).__createBinding || (Object.create ? function (t, e, r, n) {
        void 0 === n && (n = r), Object.defineProperty(t, n, {
          enumerable: !0,
          get: function () {
            return e[r];
          }
        });
      } : function (t, e, r, n) {
        void 0 === n && (n = r), t[n] = e[r];
      }),
          o = (this || _global) && (this || _global).__exportStar || function (t, e) {
        for (var r in t) "default" === r || Object.prototype.hasOwnProperty.call(e, r) || n(e, t, r);
      };

      Object.defineProperty(e, "__esModule", {
        value: !0
      }), o(r(252), e), o(r(330), e), o(r(208), e), o(r(349), e), o(r(130), e), o(r(581), e), o(r(667), e), o(r(855), e), o(r(702), e), o(r(164), e);
    },
    47: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    64: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    712: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    77: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    589: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    457: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    796: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    247: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    676: (t, e) => {
      "use strict";

      Object.defineProperty(e, "__esModule", {
        value: !0
      });
    },
    799: function (t, e, r) {
      "use strict";

      var n = (this || _global) && (this || _global).__createBinding || (Object.create ? function (t, e, r, n) {
        void 0 === n && (n = r), Object.defineProperty(t, n, {
          enumerable: !0,
          get: function () {
            return e[r];
          }
        });
      } : function (t, e, r, n) {
        void 0 === n && (n = r), t[n] = e[r];
      }),
          o = (this || _global) && (this || _global).__exportStar || function (t, e) {
        for (var r in t) "default" === r || Object.prototype.hasOwnProperty.call(e, r) || n(e, t, r);
      };

      Object.defineProperty(e, "__esModule", {
        value: !0
      }), o(r(47), e), o(r(64), e), o(r(589), e), o(r(457), e), o(r(247), e), o(r(676), e), o(r(712), e), o(r(77), e), o(r(796), e);
    },
    717: function (t, e, r) {
      "use strict";

      var n = (this || _global) && (this || _global).__createBinding || (Object.create ? function (t, e, r, n) {
        void 0 === n && (n = r), Object.defineProperty(t, n, {
          enumerable: !0,
          get: function () {
            return e[r];
          }
        });
      } : function (t, e, r, n) {
        void 0 === n && (n = r), t[n] = e[r];
      }),
          o = (this || _global) && (this || _global).__exportStar || function (t, e) {
        for (var r in t) "default" === r || Object.prototype.hasOwnProperty.call(e, r) || n(e, t, r);
      };

      Object.defineProperty(e, "__esModule", {
        value: !0
      }), o(r(961), e), o(r(515), e), o(r(868), e), o(r(799), e), o(r(974), e);
    }
  },
      e = {};

  function r(n) {
    var o = e[n];
    if (void 0 !== o) return o.exports;
    var i = e[n] = {
      exports: {}
    };
    return t[n].call(i.exports, i, i.exports, r), i.exports;
  }

  r.n = t => {
    var e = t && t.__esModule ? () => t.default : () => t;
    return r.d(e, {
      a: e
    }), e;
  }, r.d = (t, e) => {
    for (var n in e) r.o(e, n) && !r.o(t, n) && Object.defineProperty(t, n, {
      enumerable: !0,
      get: e[n]
    });
  }, r.o = (t, e) => Object.prototype.hasOwnProperty.call(t, e), r.r = t => {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(t, "__esModule", {
      value: !0
    });
  };
  var n = {};

  (() => {
    "use strict";

    r.r(n), r.d(n, {
      default: () => i,
      Particles: () => t.Z
    });
    var t = r(312),
        e = r(717),
        o = {};

    for (const t in e) ["default", "Particles"].indexOf(t) < 0 && (o[t] = () => e[t]);

    r.d(n, o);
    const i = t.Z;
  })();

  var o = exports;

  for (var i in n) o[i] = n[i];

  n.__esModule && Object.defineProperty(o, "__esModule", {
    value: !0
  });
})();

export default exports;